<template>
  <ValidationObserver
    ref="observer"
    tag="form"
    class="c-form--default c-form--modern"
    slim
    :class="{ 'is-loading': loading }"
    @submit.prevent="onSubmit"
  >
    <div
      v-if="showSuccess"
      v-html="model.successHtml"
    />
    <template v-else>
      <div class="c-form-row c-form-column-grid">
        <ValidationProvider
          v-slot="{ errors, classes }"
          :name="model.firstNameLabel"
          rules="required"
          tag="div"
        >
          <label
            for="contact-form-firstName"
            class="c-label--s"
            v-text="model.firstNameLabel"
          />
          <input
            id="contact-form-firstName"
            v-model="formModel.firstName"
            :class="classes"
            :placeholder="model.firstNameHint"
            class="c-text-input"
            type="text"
          >
          <span
            v-if="errors.length"
            class="c-error-message u-text-error"
          >
            {{ errors[0] }}
          </span>
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors, classes }"
          :name="model.lastNameLabel"
          rules="required"
          tag="div"
        >
          <label
            for="contact-form-lastName"
            class="c-label--s"
            v-text="model.lastNameLabel"
          />
          <input
            id="contact-form-lastName"
            v-model="formModel.lastName"
            :class="classes"
            :placeholder="model.lastNameHint"
            class="c-text-input"
            type="text"
          >
          <span
            v-if="errors.length"
            class="c-error-message u-text-error"
          >
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>
      <ValidationProvider
        v-slot="{ errors, classes }"
        :name="model.emailLabel"
        rules="required|email"
        tag="div"
        class="c-form-row"
      >
        <label
          for="contact-form-email"
          class="c-label--s"
          v-text="model.emailLabel"
        />
        <input
          id="contact-form-email"
          v-model.lazy="formModel.email"
          :class="classes"
          :placeholder="model.emailHint"
          class="c-text-input"
          type="email"
        >
        <span
          v-if="errors.length"
          class="c-error-message u-text-error"
        >
          {{ errors[0] }}
        </span>
      </ValidationProvider>
      <div class="c-form-row c-form-column-grid">
        <ValidationProvider
          v-if="!model.selectedIndustry"
          v-slot="{ errors, classes }"
          :name="model.industryLabel"
          rules="required"
          tag="div"
        >
          <label
            for="contact-form-industry"
            class="c-label--s"
            v-text="model.industryLabel"
          />
          <Dropdown
            id="contact-form-industry"
            v-model="formModel.industry"
            :class="classes"
            class="c-form-dropdown"
            :hint="model.industryHint"
            :items="{group: model.industries}"
          />
          <span
            v-if="errors.length"
            class="c-error-message u-text-error"
          >
            {{ errors[0] }}
          </span>
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors, classes }"
          :name="model.countryLabel"
          rules="required"
          tag="div"
        >
          <label
            for="contact-form-country"
            class="c-label--s"
            v-text="model.countryLabel"
          />
          <Dropdown
            id="contact-form-country"
            v-model="formModel.country"
            :class="classes"
            class="c-form-dropdown"
            :hint="model.countryHint"
            :items="{highlighted: model.highlightedCountries, main: model.countries}"
          />
          <span
            v-if="errors.length"
            class="c-error-message u-text-error"
          >
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>
      <div class="c-form-row">
        <span
          class="c-label--s u-font-size-dates"
          v-html="model.termsHtml"
        />
      </div>

      <p
        v-if="errorMessage"
        class="u-text-error u-mt--l u-font-size-dates"
        v-text="errorMessage"
      />

      <div class="c-form-row">
        <button
          type="submit"
          class="c-button"
          v-text="model.sendButtonText"
        />
      </div>
    </template>

    <CssLoader
      v-if="loading"
      class="css-loader"
    />

    <recaptcha
      ref="recaptchaform"
      :recaptcha-key="model.recaptchaKey"
      :recaptcha-base-url="model.recaptchaBaseUrl"
      @submit="submitForm"
    />
  </ValidationObserver>
</template>

<script>
import { ref, reactive } from '@vue/composition-api'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'
import Recaptcha from '@/Redesign/Components/Recaptcha.vue'
import Dropdown from '@/Redesign/Components/Dropdown.vue'
import CssLoader from '@/Redesign/Components/CssLoader.vue'
import api from '@/Redesign/Lib/api'
import { getReferenceUrl, getPageUrl } from '@/Redesign/Helpers/url'

// TODO: Add proper translation for messages
extend('required', required)
extend('email', email)

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    CssLoader,
    Recaptcha,
    Dropdown
  },
  props: {
    model: {
      type: Object,
      default() {
        return {
          header: '',
          industries: [],
          countries: [],

          firstNameHint: '',
          lastNameHint: '',
          emailHint: '',
          countryHint: '',
          industryHint: '',

          firstNameLabel: '',
          lastNameLabel: '',
          emailLabel: '',
          countryLabel: '',
          industryLabel: '',
          privacyPolicyLabel: '',
          acceptSubscriptionLabel: '',

          selectedIndustry: null,
          permissionVersion: '',
          recaptchaKey: '',
          recaptchaBaseUrl: '',
          subscriptionHtml: '',
          sendButtonText: '',
          termsHtml: '',
          successHtml: '',
          errorText: '',
          language: '',
          recaptchaDisclaimerHtml: ''
        }
      }
    }
  },
  setup(props) {
    const observer = ref(null)
    const formModel = reactive({
      firstName: null,
      lastName: null,
      email: null,
      country: '',
      industry: '',
      acceptTerms: true,
      subscription: true,
      reference: getReferenceUrl(),
      pageName: document.querySelector('meta[property="og:title"]').content,
      pageUrl: getPageUrl(),
      pageId: document.body.getAttribute('data-page-id')
    })
    const showSuccess = ref(false)
    const errorMessage = ref(null)
    const loading = ref(false)
    const recaptchaform = ref(null)

    const onSubmit = async () => {
      const isValid = await observer.value.validate()
      if (!isValid || loading.value) {
        // ABORT!!
      } else {
        recaptchaform.value.verify()
      }
    }

    const submitForm = async (token) => {
      const isValid = await observer.value.validate()
      if (!isValid) {
        return
      }

      errorMessage.value = null
      loading.value = true

      api.submitSignupForm({
        ...formModel,
        industry: props.model.selectedIndustry?.length
          ? props.model.selectedIndustry : formModel.industry,
        blockId: props.model.blockId,
        permissionVersion: props.model.permissionVersion,
        language: props.model.locale
      }, token)
        .then((response) => {
          if (response && response.data && response.data.errorMessage) {
            errorMessage.value = response.data.errorMessage
          } else {
            window.Tracker.track({
              event: 'signupFormSubmit'
            })

            showSuccess.value = true
          }
        })
        .catch(() => {
          errorMessage.value = props.model.errorText
        })
        .then(() => {
          loading.value = false
          recaptchaform.value.reset()
        })
    }

    return {
      observer,
      recaptchaform,
      formModel,
      showSuccess,
      loading,
      errorMessage,

      onSubmit,
      submitForm
    }
  }
}
</script>
