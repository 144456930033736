import 'core-js/stable'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'regenerator-runtime/runtime'
import smoothscroll from 'smoothscroll-polyfill'

import Vue from 'vue'
import VueMq from 'vue-mq'
import VueCompositionApi from '@vue/composition-api'
import VModal from 'vue-js-modal'

// Vanilla scripts
import { openClose, stickyHeader } from '@/Redesign/Components/Header'
import { stickyMenu, openCloseMenu } from '@/Redesign/Components/RibbonMenu'
import anchorScroll from '@/Redesign/Components/AnchorScroll'
import downloadMaterial from '@/Redesign/Components/DownloadMaterial/DownloadMaterial'
import pressCenterTracking from '@/Redesign/Components/PressCenterTracking'
import tracker from '@/Redesign/Components/Tracking'
import copyLink from '@/Redesign/Components/CopyLink'

import WebCoreMainMenu from '@/Redesign/Components/MainMenu.vue'
import WebCoreMainMenuButton from '@/Redesign/Components/MainMenuButton.vue'
import QuickSearch from '@/Redesign/Components/QuickSearch.vue'
import SubscriptionForm from '@/Redesign/Components/SubscriptionForm.vue'
import InvisibleRecaptchaElementBlock from '@/Redesign/Components/InvisibleRecaptchaElementBlock.vue'
import PermissionElementBlock from '@/Redesign/Components/PermissionElementBlock.vue'
import IngredientsItem from '@/Redesign/Components/IngredientsItem.vue'
import HighlightedStudies from '@/Redesign/Components/HighLightedStudies.vue'
import StickyNavigation from '@/Redesign/Components/StickyNavigation.vue'
import ContactForm from '@/Redesign/Components/ContactForm.vue'
import SignupForm from '@/Redesign/Components/SignupForm.vue'

// not used inside main
import vueUtil from '@/Redesign/vue-util'
import './Scss/index.scss'
import {
  iconCarousel,
  featuredContentCarousel,
  eventsCarousel,
  linkedInFeedCarousel,
  relatedContentCarousel
} from '@/Redesign/Components/Carousel'
import downloadMaterialMobileCarousel from '@/Redesign/Components/DownloadMaterialMobileCarousel'
import responsiveVideo from '@/Redesign/Components/ResponsiveVideo'

NodeList.prototype.forEach = Array.prototype.forEach
smoothscroll.polyfill()

Vue.use(VueCompositionApi)
Vue.use(VModal)

Vue.use(VueMq, {
  breakpoints: {
    mobile: 569,
    'tablet-x': 768,
    tablet: 1025,
    desktop: Infinity
  }
})

const vueComponents = {
  WebCoreMainMenu,
  WebCoreMainMenuButton,
  QuickSearch,
  SubscriptionForm,
  InvisibleRecaptchaElementBlock,
  PermissionElementBlock,
  IngredientsItem,
  HighlightedStudies,
  StickyNavigation,
  ContactForm,
  SignupForm
}

// And all Vue components on the page
vueUtil.mountComponents(vueComponents, null)

// run vanilla scripts here
stickyHeader()
openClose()
stickyMenu()
openCloseMenu()
tracker()
featuredContentCarousel()
relatedContentCarousel()
setTimeout(() => {
  downloadMaterial()
}, 1000)
iconCarousel()
eventsCarousel()
downloadMaterialMobileCarousel()
responsiveVideo()
linkedInFeedCarousel()
anchorScroll()
pressCenterTracking()
copyLink()
