<template>
  <ValidationObserver
    ref="observer"
    tag="form"
    class="c-form--default"
    slim
    :class="{ 'is-loading': loading }"
    @submit.prevent="onSubmit"
  >
    <div
      v-if="showSuccess"
      class="c-success-summary u-text-align--center"
      v-html="successMessage"
    />
    <div class="c-form-content">
      <h3 class="h1--md">{{ model.header }}</h3>
      <p class="u-font-size-dates">{{ model.description }}</p>

      <div class="c-form-row c-form-column-container">
        <ValidationProvider
          v-slot="{ errors, classes }"
          :name="model.firstNameLabel"
          rules="required"
          tag="div"
          class="c-form-column"
        >
          <label
            for="download-materials-firstName"
            class="c-label--s"
            v-text="model.firstNameLabel"
          />
          <input
            id="download-materials-firstName"
            v-model="formModel.firstName"
            :class="classes"
            :placeholder="model.firstNameHint"
            class="c-text-input"
            type="text"
          >
          <span
            v-if="errors.length"
            class="c-error-message u-text-error"
          >
            {{ errors[0] }}
          </span>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors, classes }"
          :name="model.lastNameLabel"
          rules="required"
          tag="div"
          class="c-form-column"
        >
          <label
            for="download-materials-lastName"
            class="c-label--s"
            v-text="model.lastNameLabel"
          />
          <input
            id="download-materials-lastName"
            v-model="formModel.lastName"
            :class="classes"
            :placeholder="model.lastNameHint"
            class="c-text-input"
            type="text"
          >
          <span
            v-if="errors.length"
            class="c-error-message u-text-error"
          >
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>

      <div class="c-form-row c-form-column-container">
        <ValidationProvider
          v-slot="{ errors, classes }"
          :name="model.emailLabel"
          rules="required|email"
          tag="div"
          class="c-form-column"
        >
          <label
            for="download-materials-email"
            class="c-label--s"
            v-text="model.emailLabel"
          />
          <input
            id="download-materials-email"
            v-model.lazy="formModel.email"
            :class="classes"
            :placeholder="model.emailHint"
            class="c-text-input"
            type="email"
          >
          <span
            v-if="errors.length"
            class="c-error-message u-text-error"
          >
            {{ errors[0] }}
          </span>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors, classes }"
          :name="model.companyLabel"
          rules="required"
          tag="div"
          class="c-form-column"
        >
          <label
            for="download-materials-company"
            class="c-label--s"
            v-text="model.companyLabel"
          />
          <input
            id="download-materials-company"
            v-model="formModel.company"
            :class="classes"
            :placeholder="model.companyHint"
            class="c-text-input"
            type="text"
          >
          <span
            v-if="errors.length"
            class="c-error-message u-text-error"
          >
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>

      <div class="c-form-row c-form-column-container">
        <ValidationProvider
          v-slot="{ errors, classes }"
          :name="model.countryLabel"
          rules="required"
          tag="div"
          class="c-form-column"
        >
          <label
            for="download-materials-country"
            class="c-label--s"
            v-text="model.countryLabel"
          />
          <Dropdown
            v-model="formModel.country"
            id="download-materials-country"
            :class="classes"
            class="c-form-dropdown"
            :hint="model.countryHint"
            :items="{highlighted: model.highlightedCountries, main: model.countries}"
          />
          <span
            v-if="errors.length"
            class="c-error-message u-text-error"
          >
            {{ errors[0] }}
          </span>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors, classes }"
          :name="model.industryLabel"
          rules="required"
          tag="div"
          class="c-form-column"
        >
          <label
            for="download-materials-industry"
            class="c-label--s"
            v-text="model.industryLabel"
          />
          <Dropdown
            v-model="formModel.industry"
            id="download-materials-industry"
            :class="classes"
            class="c-form-dropdown"
            :hint="model.industryHint"
            :items="{group: model.industries}"
          />
          <span
            v-if="errors.length"
            class="c-error-message u-text-error"
          >
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>

      <div class="c-form-row c-form-column-container">
        <ValidationProvider
          v-slot="{ errors, classes }"
          :name="model.titleLabel"
          rules="required"
          tag="div"
          class="c-form-column"
        >
          <label
            for="download-materials-title"
            class="c-label--s"
            v-text="model.titleLabel"
          />
          <Dropdown
            v-model="formModel.title"
            id="download-materials-title"
            :class="classes"
            class="c-form-dropdown"
            :hint="model.titleHint"
            :items="{group: model.titles}"
          />
          <span
            v-if="errors.length"
            class="c-error-message u-text-error"
          >
            {{ errors[0] }}
          </span>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors, classes }"
          :name="model.downloadCategoryLabel"
          rules="required"
          tag="div"
          class="c-form-column"
        >
          <label
            for="download-materials-category"
            class="c-label--s"
            v-text="model.downloadCategoryLabel"
          />
          <Dropdown
            v-model="formModel.category"
            id="download-materials-category"
            :class="classes"
            class="c-form-dropdown"
            :hint="model.downloadCategoryHint"
            :items="{group: model.downloadCategories}"
          />
          <span
            v-if="errors.length"
            class="c-error-message u-text-error"
          >
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>

      <div
        class="c-disclaimer u-mt--l u-font-size-dates"
        v-html="model.disclaimerHtml"
      />

      <div class="c-form-row">
        <ValidationProvider
          v-if="!model.hidePermissionCheckboxes"
          v-slot="{ errors, classes }"
          rules="required"
          :name="model.privacyPolicyLabel"
          tag="label"
          class="c-checkbox"
          for="download-materials-terms-of-use"
        >
          <input
            id="download-materials-terms-of-use"
            v-model="formModel.privacyPolicy"
            value="terms"
            :class="classes"
            class="c-checkbox__input"
            type="checkbox"
          >
          <span
            class="c-checkbox__label u-font-size-dates"
            v-html="model.termsHtml"
          />
          <span class="c-checkbox-icon" />
          <div
            v-if="errors.length"
            class="c-error-message u-text-error"
          >
            {{ errors[0] }}
          </div>
        </ValidationProvider>
        <ValidationProvider
          v-if="!model.hidePermissionCheckboxes"
          v-slot="{ errors, classes }"
          rules="required"
          :name="model.acceptSubscriptionLabel"
          tag="label"
          class="c-checkbox"
          for="download-materials-newsletter"
        >
          <input
            id="download-materials-newsletter"
            v-model="formModel.acceptSubscription"
            value="subscription"
            :class="classes"
            class="c-checkbox__input"
            type="checkbox"
          >
          <span
            class="c-checkbox__label u-font-size-dates"
            v-html="model.subscriptionHtml"
          />
          <span class="c-checkbox-icon" />
          <div
            v-if="errors.length"
            class="c-error-message u-text-error"
          >
            {{ errors[0] }}
          </div>
        </ValidationProvider>
      </div>

      <p
        v-if="errorMessage"
        class="u-text-error u-mt--l u-font-size-dates"
        v-text="errorMessage"
      />

      <div class="c-form-row">
        <input
          type="submit"
          :value="model.sendButtonText"
          class="c-button"
        >
      </div>
    </div>

    <CssLoader
      v-if="loading"
      class="css-loader"
    />

    <recaptcha
      ref="recaptchaform"
      :recaptcha-key="model.recaptchaKey"
      :recaptcha-base-url="model.recaptchaBaseUrl"
      @submit="submitForm"
    />
  </ValidationObserver>
</template>

<script>
import {ref, onMounted, reactive, computed} from '@vue/composition-api'
import useDownloadMaterial from '@/Redesign/Compositions/useDownloadMaterial'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'
import Recaptcha from '@/Redesign/Components/Recaptcha.vue'
import CssLoader from '@/Redesign/Components/CssLoader.vue'
import Dropdown from '@/Redesign/Components/Dropdown.vue'
import api from '@/Redesign/Lib/api'
import { getReferenceUrl, getPageUrl } from "@/Redesign/Helpers/url";

// TODO: Add proper translation for messages
extend('required', required)
extend('email', email)

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    CssLoader,
    Recaptcha,
    Dropdown
  },
  props: {
    model: {
      type: Object,
      default() {
        return {
          header: '',
          description: '',
          downloadCategories: [],
          countries: [],
          industries: [],
          titles: [],

          firstNameHint: '',
          lastNameHint: '',
          emailHint: '',
          companyHint: '',
          countryHint: '',
          industryHint: '',
          titleHint: '',
          downloadCategoryHint: '',

          firstNameLabel: '',
          lastNameLabel: '',
          emailLabel: '',
          companyLabel: '',
          countryLabel: '',
          industryLabel: '',
          titleLabel: '',
          downloadCategoryLabel: '',
          privacyPolicyLabel: '',
          acceptSubscriptionLabel: '',

          permissionVersion: '',
          recaptchaKey: '',
          recaptchaBaseUrl: '',
          disclaimerHtml: '',
          subscriptionHtml: '',
          sendButtonText: '',
          termsHtml: '',
          successHtml: '',
          errorText: '',
          language: ''
        }
      }
    }
  },
  $_veeValidate: {
    validator: 'new'
  },
  setup(props) {
    const observer = ref(null)
    const { state } = useDownloadMaterial()
    const formModel = reactive({
      firstName: null,
      lastName: null,
      email: null,
      company: null,
      country: '',
      industry: '',
      title: '',
      category: '',
      privacyPolicy: [],
      acceptSubscription: [],
      reference: getReferenceUrl(),
      pageName: document.querySelector('meta[property="og:title"]').content,
      pageUrl: getPageUrl(),
      pageId: document.body.getAttribute('data-page-id')
    })
    const showSuccess = ref(false)
    const errorMessage = ref(null)
    const loading = ref(false)
    const recaptchaform = ref(null)
    const fileUrl = ref(null)
    const ahrefTemplate = '<a href="{0}" target="_blank">'

    const onSubmit = async () => {
      const isValid = await observer.value.validate()
      if (!isValid) {
        // ABORT!!
      } else {
        recaptchaform.value.verify()
      }
    }

    const createDownloadLink = (url) => {
      const filename = url.split('/').pop().split('?').shift()
      const link = document.createElement('a')
      link.target = '_blank'
      link.href = url
      link.download = filename
      link.click()
    }

    const submitForm = async (token) => {
      const isValid = await observer.value.validate()
      if (!isValid) {
        return
      }

      errorMessage.value = null
      loading.value = true

      api.submitDownloadForm({
        ...formModel,
        acceptTerms: formModel.privacyPolicy[0] === 'terms',
        acceptSubscription: true,
        fileUrl: state.fileUrl,
        fileName: state.fileName,
        blockId: state.blockId,
        permissionVersion: props.model.permissionVersion,
        language: props.model.locale
      }, token)
        .then((response) => {
          if (response && response.data && response.data.errorMessage) {
            errorMessage.value = response.data.errorMessage
          } else {
            fileUrl.value = response.data
            if (state.materialTitle) {
              window.Tracker.track({
                event: 'downloadMaterialsFormSubmit',
                materialTitle: state.materialTitle
              })
            }
            if (fileUrl.value) {
              createDownloadLink(fileUrl.value)
            }
            showSuccess.value = true
          }
        })
        .catch(() => {
          errorMessage.value = props.model.errorText
        })
        .then(() => {
          loading.value = false
          recaptchaform.value.reset()
        })
    }

    onMounted(() => {
      setTimeout(() => {
        observer.value.reset() // FIX: IE11 invalid field on component init
      }, 0)
    })

    return {
      observer,
      recaptchaform,
      formModel,
      showSuccess,
      onSubmit,
      submitForm,
      loading,
      successMessage: computed(() => props.model.successHtml.replace('<a>', ahrefTemplate.replace('{0}', fileUrl.value))),
      errorMessage
    }
  }
}
</script>
